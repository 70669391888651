import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Seo from "../components/seo";
import toKebabCase from "../utils/to-kebab-case";
import Layout from "../components/layout";
import { TagIcon } from "../components/icon";

const TagsPage = ({
  location,
  data: {
    allMdx: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout location={location} title={title}>
    <Seo
      title={`List of available tags`}
      keywords={group.map((tag) => tag.fieldValue).slice(0, 10)}
      description={`All posts have some tags associated with them. These tags will help you filter out posts of your interest.`}
    />
    <h1
      id="all-tags"
      css={`
        margin-block-start: 0;
      `}
    >
      Tags <TagIcon />
    </h1>
    <ul
      aria-labelledby="all-tags"
      css={`
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(16ch, 1fr));
        gap: 0.25rem;
      `}
    >
      {group.map((tag) => (
        <li key={tag.fieldValue}>
          <Link to={`/tags/${toKebabCase(tag.fieldValue)}/`}>
            {tag.fieldValue} ({tag.totalCount})
          </Link>
        </li>
      ))}
    </ul>
  </Layout>
);

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
